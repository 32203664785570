html {
  font-family: Arial, Helvetica, sans-serif; }

body {
  padding: 2em; }

.main-container {
  display: grid;
  max-width: 500px;
  margin: auto; }

.password-header {
  margin-bottom: 1em !important; }

.hidden-field {
  display: none; }

.reset-email-form {
  cursor: pointer;
  text-decoration: underline; }
  .reset-email-form:hover {
    text-decoration: none; }
